<template>
  <div class="pageMain">
    <!-- 企业备案 -->
    <div class="content">
      <!-- 左边列表 -->
      <CustomTables
        :data="tableData"
        height="55vh"
        :total="total"
        class="tableHear"
        :headerCellStyle="headerCellStyle"
        :titleIcon="true"
      >
        <template slot="search">
          <SearchLeft
            @search="search"
            @reset="reset"
            :iconShow="true"
            :infoShow="true"
            info="今日报警"
          >
            <el-form :inline="true" :model="searchForm">
              <el-form-item class="formItem">
                <el-input
                @input="change"
                  v-model="searchForm.projName"
                  size="small"
                  placeholder="项目"
                ></el-input>
              </el-form-item>
            </el-form>
          </SearchLeft>
        </template>

        <el-table-column type="index" label="排序" width="50" align="center">
        </el-table-column>
        <el-table-column prop="projName" label="项目名称" align="center">
        </el-table-column>
        <el-table-column
          prop="alarmCount"
          label="总报(次)"
          align="center"
          width="100"
        >
        </el-table-column>
      </CustomTables>
      <div class="tableBox">
        <CustomTables
          :data="tableDatas"
          :headerCellStyle="headerCellStyle"
          height="250"
          :total="totals"
          title="项目设备列表"
          @selectionChange="handleSelectionChange"
          @handleCurrentChanges="handleCurrentChanges"
          class="tables"
        >
          <template slot="tabs">
            <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              v-for="(tab,index) in tabs"
              :key="tab.name"
              :label="tab.label"
              :name="tab.name"
            >
           
            </el-tab-pane>
          </el-tabs> -->
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane   name="0">
                <span slot="label" > 塔机 ({{ count.towerCount? count.towerCount:0}})</span>
                </el-tab-pane>
                <el-tab-pane name="1">
                <span slot="label"> 升降机({{ count.elevatorCount? count.elevatorCount:0}})</span>
                </el-tab-pane>
                <el-tab-pane >
                <span slot="label" name="2"> 龙门吊 ({{ count.gantrycraneCount? count.gantrycraneCount:0}})</span>
                </el-tab-pane>
                <el-tab-pane >
                <span slot="label" name="3"> 架桥机 ({{ count.bridgeCount? count.bridgeCount:0}})</span>
                </el-tab-pane>
                <el-tab-pane >
                <span slot="label" name="5"> 挂篮 ({{ count.basketCount? count.basketCount:0}})</span>
                </el-tab-pane>
            
            </el-tabs>
          </template>
          <el-table-column type="selection" align="center" width="55">
          </el-table-column>
          <el-table-column prop="actNo" label="现场编号" align="center">
          </el-table-column>
          <el-table-column prop="deviceType" label="设备类型" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.deviceType == 0">塔吊</span>
              <span v-if="scope.row.deviceType == 1">升降机</span>
              <span v-if="scope.row.deviceType == 2">龙门吊</span>
              <span v-if="scope.row.deviceType == 3">架桥机</span>
              <span v-if="scope.row.deviceType == 4">爬模</span>
              <span v-if="scope.row.deviceType == 5">挂篮</span>
            </template>
          </el-table-column>
          <el-table-column prop="equipNo" label="起重设备编号" align="center">
          </el-table-column>
          <el-table-column prop="deviceNo" label="监控主机IMEI" align="center">
          </el-table-column>
          <el-table-column prop="alarmCount" label="违章次数" align="center">
          </el-table-column>
        </CustomTables>
        <CustomTables
          :data="tableData3"
          :headerCellStyle="headerCellStyle"
          height="250"
          :total="total3"
          title="报警详情"
        
          @selectionChange="handleSelectionChanges"
        >
          <el-table-column type="selection" align="center" width="55">
          </el-table-column>
          <el-table-column prop="alarmTime" label="运行时间" align="center">
          </el-table-column>
          <el-table-column prop="alarmType" label="报警类型" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.alarmType == 0">告警</span>
              <span v-if="scope.row.alarmType == 1">违章</span>
              
            </template>
          </el-table-column>
          <el-table-column prop="alarmDetail" label="运行状态" align="center">
          </el-table-column>
          <el-table-column label="详情" align="center" width="100">
            <template slot-scope="scope">
              <span @click="detailItem(scope.row)" class="detail">处理</span>
            </template>
          </el-table-column>
        </CustomTables>
      </div>
    </div>
  </div>
</template>

<script>
import CustomTables from "../../components/common/CustomTables";
import SearchHead from "../../components/common/SearchHead";
import SearchLeft from "../../components/common/SearchLeft.vue";
import monitor from "../../api/modules/monitor";
export default {
  components: { CustomTables, SearchHead, SearchLeft },
  props: ["compId"],
  name: "monitorAlarm",
  data() {
    return {
      tableData: [],
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      searchForms: {
        pageNum: 1,
        pageSize: 10,
      },
      searchForm3: {
        pageNum: 1,
        pageSize: 10,
      },
      activeName: "0",
      tabs: [
        {
          label: "塔机",
          name: "tower",
        },
        {
          label: "升降机",
          name: "towers",
        },
        {
          label: "龙门吊",
          name: "towers",
        },
        {
          label: "架桥机",
          name: "towers",
        },
        {
          label: "挂篮",
          name: "towers",
        },
      ],
      total: 0,
      tableDatas:[],
      tableData3:[],
      total3:0,
      totals:0,
      count:'',
      projId:'',
      deviceNo:''
    };
  },
  mounted() {
    // console.log('this.compId',this.compId);
    this.getProject();
  },
  methods: {
    getProject() {
      this.searchForm.compId = this.compId;
      monitor.getProjectAlarmListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
        if( this.tableData.length>0){
          this.ProjectDeviceCount(this.tableData[0].projId);
        }else{
          // this.ProjectDeviceCount()
        }
       
      });
    },
    ProjectDeviceCount(projId) {
      monitor.getProjectDeviceCount(projId).then((res) => {
        console.log("resx", res);
        this.count=res.data
        this.projId=projId
        this.getProjectDeviceListByPages(projId,0)
      });
    },
    getProjectDeviceListByPages(projId,deviceType){
      let params = {
        deviceType:deviceType,
        projId:projId,
        currPage:this.searchForms.pageNum,
        pageSize:this.searchForms.pageSize
      }
      monitor.getProjectDeviceListByPage(params).then((res) => {
        this.totals = res.data.total;
        this.tableDatas = res.data.records;
        if(this.tableDatas.length>0){
          this.getAlarmByPages( this.tableDatas[0].deviceNo)
        this.deviceNo=this.tableDatas[0].deviceNo
        }else{
          this.getAlarmByPages( )
        }
       
      });
    },
    getAlarmByPages(deviceNo){
      let params = {
        deviceImei:deviceNo,
        currPage:this.searchForm3.pageNum,
        pageSize:this.searchForm3.pageSize
      }
      monitor.getAlarmByPage(params).then((res) => {
        this.total3 = res.data.total;
        this.tableData3 = res.data.records;
       
      });
    },
    change(e){
      console.log('e',e);
      this.searchForm.projName=e
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getProject(this.searchForm)
      
    },
    search() {
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getProject(this.searchForm)
    },
    reset() {
      this.searchForm = {};
    },
    handleClick(tab, event) {
      console.log(tab.paneName);
      this.getProjectDeviceListByPages(this.projId,tab.paneName)
    },
    handleCurrentChanges(val){
      console.log('val',val);
      this.getAlarmByPages(val.deviceNo)
      this.deviceNo=val.deviceNo
      
    },
    detailItem(item) {
      
      this.$confirm(`此操作将处理该报警, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //{staffId:[item.staffId]}
        //  return
        monitor.disposeAlarm(item.alarmId).then((res) => {
          this.$message({
            message: "处理成功",
            type: "success",
          });
          this.getAlarmByPages( this.deviceNo);
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      })
    },
    handleSelectionChange() {},
    handleSelectionChanges() {},
  },
};
</script>

<style scoped lang="less">
.content {
  display: flex;
  justify-content: space-between;
}
.tableBox {
  // margin-left: 10px;
  width: 72%;
}
.tableHear {
  width: 25%;
  // background:#e4ebf1
}
.title {
  margin-bottom: 10px;
  color: #000;
}

.formItem {
  margin-bottom: 0px;
}
.tables {
  margin-bottom: 10px;
}
</style>
