<template>
    <div class="pageMain">
      <div v-if="infoShow">
      <search-head :model="searchForm" @search="search" @reset="reset"  :isExport="true">
        <el-form :inline="true" :model="searchForm">
        <el-form-item label="公司名称">
          <el-input v-model="searchForm.compName" size="small" placeholder="公司名称"></el-input>
        </el-form-item>
        <el-form-item label="设备类型">
          <el-select
                v-model="searchForm.deviceType"
                size="small"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in equipTypeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="开始日期">
                <el-date-picker
                  v-model="searchForm.startTime"
                  type="datetime"
                  class="date"
                  format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择开始日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="结束日期">
                <el-date-picker
                  v-model="searchForm.endTime"
                  type="datetime"
                  class="date"
                  format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择结束日期"
                >
                </el-date-picker>
              </el-form-item>
      </el-form>
      </search-head>
      <custom-tables :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
        
          :total="total"
          title="报警排名">
        <!-- <template slot="btns">
          <el-button type="primary" size="small" @click="addFrom">新建</el-button>
        </template> -->
        <el-table-column type="selection" align="center" width="55">
      </el-table-column>
        <el-table-column
            prop="compName"
            label="公司名称"
            align="center"
          >
        </el-table-column>
        <el-table-column
            prop="deviceCount"
            align="center"
            label="设备总数"
          >
        </el-table-column>
        <el-table-column
            prop="alarmCount"
            align="center"
            label="报警次数"
          >
        </el-table-column>
      
       
        <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <span
                @click="detailItem(scope.row)"
                class="detail"
              >查看</span>
            </template>
          </el-table-column>
      </custom-tables>
     
    </div>
    <!-- <monitorAlarm v-if="editShow" :compId="compId"></monitorAlarm> -->
    </div>
  </template>
  
  <script>
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import CustomDialog from "../../../components/common/CustomDialog";
import monitorAlarm from '../../../components/monitor/monitorAlarm.vue'
import monitor from "../../../api/modules/monitor";
import {equipType,useState} from "../../../config/dataStatus"
  export default {
    name: "index",
    components: {CustomDialog, CustomTables, SearchHead,monitorAlarm},
    data() {
      return {
        equipTypeList:equipType,
        tableData: [
         
        ],
        searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
        total:0,
        showDialog: false,
        headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      editShow:false,
      infoShow:true,
      compId:''
      }
    },
    mounted() {
      this.getList()
    },
    methods:{
      getList() {
      monitor.getCompanyAlarmListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getList();
    },
      addFrom(){
        this.showDialog = true
      },
      close(){
        this.showDialog = false
      },
      submit(){
        this.showDialog = false
      },
      detailItem(item){
        //详情
        this.$router.push({
       path: `/view/monitor/alarmDel`,
       query: {
        item: item.compId,
      
    }
     });
        
    },
    handleSelectionChange(val){
        console.log(val);
        
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
  
    }
  }
  </script>
  
  <style scoped lang="less">

  </style>